@keyframes rainbowText {
  0% {
    background-position: -50% 0%;
    background-size: "2000%, 0%";
  }
  100% {
      background-position: 0% 100%;
      background-size: "2000%, 0%";
  }
}

@keyframes understandAnim {
  0% {
    background-position: 26.5% 0%;
  }
  100% {
    background-position: 0% 0%;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    scale: 0.95;
  } 100% {
    opacity: 1;
    scale: 1
  }
}

.fadeInClass {
  opacity: 0;
  animation: fadeIn 0.75s cubic-bezier(.48,.8,.84,1.03);
  animation-fill-mode: forwards;
}

.understandButtonClass {
  background: "linear-gradient(90deg, rgba(0,255,0,1) 0%, rgba(0,255,0,1) 0%, rgba(255,255,255,1) 6%, rgba(255,255,255,1) 6%)";
  background-size: "2000%, 0px";
  animation: understandAnim 3s linear;
}

.rainbowTextClass {
  background: linear-gradient(45deg, rgba(255, 0, 89, 1) 0%, rgba(0, 179, 255, 1) 33%, rgba(255, 0, 89, 1) 66%, rgba(0, 179, 255, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  animation: rainbowText 1.5s linear infinite;
  background-size: 400% 400%;
  color: transparent;
}

.rainbowTextClass2 {
  background: linear-gradient(45deg, rgba(255, 255, 255, 1) 0%, rgba(255, 0, 0, 1) 33%, rgba(255, 255, 255, 1) 66%, rgba(255, 0, 0, 1) 100%);
  -webkit-background-clip: text;
  background-clip: text;
  animation: rainbowText 4s linear infinite;
  background-size: 400% 400%;
  color: transparent;
}

::-webkit-scrollbar {
    width: 10px;
    background-color: #474545;
    border-radius: 12px;
  }

::-webkit-scrollbar-thumb {
    background: #888888;
    border-radius: 20px;
}

.cringe {
  color: #888888;
  cursor: pointer;
}

@keyframes dropdownToggle{
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
    transform-origin: top center;
  
  } 100% {
    opacity: 100;
    transform: translate(-50%, 0px);
  }
}

.dropDownOpen {
  animation: dropdownToggle 0.5s forwards normal;
}

.dropDownClose {
  animation: dropdownToggle2 0.5s backwards reverse
}

.cringe:hover {
  color: green;
  transition: 0.2s;
  text-decoration: wavy;
}

@font-face {
  font-family: 'MyFont';
  src: local('MyFont'), url(./Inconsolata-VariableFont_wdth.ttf) format('truetype');
}

@font-face {
  font-family: "Tilt-Neon";
  src: local("Tilt-Neon"), url(./ShareTechMono-Regular.ttf) format("truetype");
}

@keyframes
errorAnim {
  0%, 75% {
    opacity: 1;
  } 100% {
    opacity: 0;
  }
}

.errorTextClass {
  animation: errorAnim 8s
}