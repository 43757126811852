.gridBody {
    height: 200vh;
    display: grid;
    grid-template: repeat(20, 1fr) / repeat(5, 1fr);
    position: "absolute"
  }
  
  .cell {
    height: 100%;
    width: 100%;
    z-index: 1000000000000000;
    position: "relative"
  }
  
  @for $i from 0 to 101 {
    .cell:nth-child(#{$i}):hover {
       // --positionX: #{$i} - (floor(#{$i / 10}) * 10);
       // --positionY: floor(#{$i / 10});
       
     }
   }
  
  .content {
    --positionX: 0;
    --positionY: 0;
    
	width: 100%;
    position: absolute;
  }

  .h1Item {
    position: absolute;
   }